body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #1C1D20; */
  /* color: #fff; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#api_docs .swagger-ui .scheme-container {
  display: none;
}

#api_docs .swagger-ui .opblock-description-wrapper h4, 
#api_docs .swagger-ui .opblock-external-docs-wrapper h4,
#api_docs .swagger-ui .opblock-title_normal h4,
#api_docs .swagger-ui table thead tr td, 
#api_docs .swagger-ui table thead tr th,
#api_docs .swagger-ui .parameter__name,
#api_docs .swagger-ui .parameter__type,
#api_docs .swagger-ui .opblock-description-wrapper,
#api_docs .swagger-ui .opblock-external-docs-wrapper,
#api_docs .swagger-ui .opblock-title_normal,
#api_docs .swagger-ui .opblock .opblock-summary-operation-id, 
#api_docs .swagger-ui .opblock .opblock-summary-path, 
#api_docs .swagger-ui .opblock .opblock-summary-path__deprecated {
  color: #AAA;
}

#api_docs .swagger-ui,
#api_docs .swagger-ui .opblock .opblock-summary-description,
#api_docs .swagger-ui .tab li,
#api_docs .swagger-ui .response-col_links,
#api_docs .swagger-ui .opblock-description-wrapper p,
#api_docs .swagger-ui .opblock-external-docs-wrapper p,
#api_docs .swagger-ui .opblock-title_normal p {
  color: #999;
}

#api_docs .swagger-ui .opblock .opblock-section-header {
  background-color: #212121;
}

#api_docs .swagger-ui svg:not(:api_docs) {
  fill:#AAA;
}

#api_docs .swagger-ui .opblock .opblock-section-header,
#api_docs .swagger-ui .info .title,
#api_docs .swagger-ui .opblock-tag,
#api_docs .swagger-ui .opblock .opblock-section-header h4,
#api_docs .swagger-ui .response-col_status,
#api_docs .swagger-ui .response-col_description,
#api_docs .swagger-ui .btn,
#api_docs .swagger-ui .json-schema-2020-12__title, 
#api_docs .swagger-ui .json-schema-2020-12-property .json-schema-2020-12__title,
#api_docs .swagger-ui .json-schema-2020-12-keyword__name--primary {
  color: #BBB;
}

#api_docs .swagger-ui .json-schema-2020-12-expand-deep-button,
#api_docs .swagger-ui .json-schema-2020-12-accordion {
  background-color: transparent;
}

#api_docs .swagger-ui .json-schema-2020-12__attribute--primary {
  color: #9595ff;
}

.cls-1 {
  
  /* filter: url(#outer-glow-12); */
}

.cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-10, .cls-11, .cls-12, .cls-13, .cls-14, .cls-15, .cls-16 {
  stroke-width: 0px;
}

.pdf-document, .pdf-page {
  background-color: transparent;
  max-width: 720px;
}

.pdf-document {
  margin: 0 auto;
}

@media screen and (min-width: 500px) {
  .speech-bubble {
    min-width: 384px;
    max-width: 70%;
  }
  
}